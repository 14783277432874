import type { Extent } from "ol/extent";

export const LS_MAP_EXTENT_KEY = "moco3.notificationsMap.extent";

export default function getExtentFromString(
  string?: string,
): Extent | undefined {
  if (!string) {
    return;
  }
  if (string.includes("|")) {
    return string.split("|").map((s) => {
      return parseFloat(s);
    });
  }
  return string.split(",").map((s) => {
    return parseInt(s);
  });
}
