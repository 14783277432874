import Link from "next/link";
import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

import { FOCUS_VISIBLE_CLASS_NAME } from "@/lib/constants";

const focusClassName = "border-3 border-[var(--tw-ring-color)]";
const paddingClassName = "px-4 py-2";
const fontClassName = "text-sm font-medium text-white";
const flexClassName = "flex gap-2 items-center justify-center grow-0";

const classNameBase = twMerge(
  "border border-transparent rounded-md shadow-2xs p-2",
  focusClassName,
  flexClassName,
  fontClassName,
);

// blue
const classNamePrimary = twMerge(
  `bg-blue hover:bg-blue-800 focus:ring-blue`,
  classNameBase,
);

// gray
const classNameSecondary = twMerge(
  `bg-gray hover:bg-gray-800 focus:ring-gray`,
  classNameBase,
);

// green
const classNameGreen = twMerge(
  `h-[44px] cursor-pointer rounded-full bg-green-500 hover:bg-green-600 disabled:opacity-50`,
  flexClassName,
  fontClassName,
  paddingClassName,
);

// danger
const classNameDanger = twMerge(
  `cursor-pointer rounded-full bg-red-600 shadow-2xs hover:bg-red-700 focus:ring-blue-500`,
  flexClassName,
  focusClassName,
  fontClassName,
  paddingClassName,
);

// outlined
const classNameOutlined = twMerge(
  `cursor-pointer border border-gray-400 bg-white hover:bg-slate-100 focus:ring-blue-500 rounded-full `,
  flexClassName,
  fontClassName,
  paddingClassName,
  `text-gray-700`,
);

// tab
const classNameTab = twMerge(
  `cursor-pointer data-[selected]:cursor-default data-[selected]:text-matisse-700 flex items-center gap-2 pb-[8px] font-semibold text-gray-600 outline-none data-[selected]:border-b-2 data-[selected]:border-b-sky-500`,
  FOCUS_VISIBLE_CLASS_NAME,
);

// link
const classNameAddItem = twMerge(
  `cursor-pointer text-matisse-700 hover:text-matisse-800 flex items-center space-x-2 text-sm font-bold`,
  `${FOCUS_VISIBLE_CLASS_NAME} outline-offset-3`,
);

type ButtonVariant =
  | "addItem"
  | "danger"
  | "green"
  | "outlined"
  | "primary"
  | "secondary"
  | "tab";

const classNameByVariant: Record<ButtonVariant, string> = {
  addItem: classNameAddItem,
  danger: classNameDanger,
  green: classNameGreen,
  outlined: classNameOutlined,
  primary: classNamePrimary,
  secondary: classNameSecondary,
  tab: classNameTab,
};

export interface Props {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  href?: string;
  onClick?: React.MouseEventHandler;
  title?: string;
  type?: "button" | "reset" | "submit";
  variant?: ButtonVariant;
}

function Button(
  {
    children,
    className,
    href,
    onClick,
    type = "button",
    variant = "primary",
    ...props
  }: Props,
  ref: React.Ref<HTMLAnchorElement | HTMLButtonElement>,
) {
  const newClassName = twMerge(classNameByVariant[variant] || "", className);
  return href ? (
    <Link
      className={newClassName}
      href={href}
      ref={ref as React.Ref<HTMLAnchorElement>}
    >
      {children}
    </Link>
  ) : (
    <button
      className={newClassName}
      onClick={onClick}
      ref={ref as React.Ref<HTMLButtonElement>}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
}

export default forwardRef(Button);
