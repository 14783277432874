import type { NotificationsTableFragment } from "@/lib/graphql";

export function getDateString(date: string): string {
  return new Date(date).toLocaleDateString("de", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

interface Props {
  data: NotificationsTableFragment;
}

function NotificationAffectedTimeIntervalRange({ data }: Props) {
  const from = data.affectedTimeIntervalsStart;
  const until = data.affectedTimeIntervalsEnd;

  if (from && until) {
    return <div>{`${getDateString(from)} – ${getDateString(until)}`}</div>;
  }

  return null;
}

export default NotificationAffectedTimeIntervalRange;
