import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid";
import {
  ChevronDownIcon,
  HomeIcon,
  NewspaperIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { twMerge } from "tailwind-merge";

import useConfig from "@/hooks/useConfig";
import useConfigContext from "@/hooks/useConfigContext";
import { PERSIST_KEY } from "@/lib/constants";

import useAuthUser from "../hooks/useAuthUser";
import getExtentFromString, {
  LS_MAP_EXTENT_KEY,
} from "../lib/getExtentFromString";

import Button from "./ui/Button";

import type { PropsWithChildren, ReactElement } from "react";

import type { Config } from "@/hooks/useConfigContext";

export function Logo({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 200 50">
      <path
        className="text-blue fill-current"
        d="M95.21 22.7a2.98 2.98 0 012.97 2.97 2.98 2.98 0 01-2.97 2.96 2.98 2.98 0 01-2.96-2.96 2.98 2.98 0 012.96-2.97z"
      />
      <path
        className="text-green fill-current"
        d="M100.27 34.44c9.28.28 16.89-9.61 14.22-18.5-2.09-8.06-11.63-13.26-19.52-9.95C87.1 8.86 83 19.06 87.17 26.45a14.84 14.84 0 0013.1 7.99zm-.55 4.94c-11.22.05-20.72-11.05-19-22.13 1.14-10.76 12.32-19.07 23-16.9 10.45 1.73 18.25 12.86 15.82 23.31-1.76 9.05-10.52 16.19-19.82 15.72z"
      />
      <path
        className="text-blue fill-current"
        d="M28 7.5H14.08c-6 0-10.87 4.13-10.87 9.22v11.3c0 5.08 4.88 9.21 10.87 9.21h20.54v3.55c0 2.75-2.98 4.99-6.64 4.99H5.72v.02l-.19-.02c-1.15 0-2.1.95-2.1 2.11s.95 2.1 2.1 2.1l.19-.01V50h22.27c6 0 10.87-4.14 10.87-9.22V16.72c0-5.1-4.88-9.23-10.87-9.23zM34.62 33H14.09c-3.66 0-6.64-2.23-6.64-4.99v-11.3c0-2.75 2.98-4.99 6.64-4.99h13.9c3.66 0 6.63 2.24 6.63 5V33z"
      />
      <path
        className="text-blue fill-current"
        d="M77.73 24.5v-7.78c0-5.09-4.87-9.23-10.87-9.23h-13.9c-6 0-10.87 4.14-10.87 9.23v11.3c0 5.08 4.87 9.21 10.87 9.21h22.68c1.16 0 2.12-.95 2.12-2.11S76.8 33 75.64 33H52.96c-3.66 0-6.64-2.24-6.64-4.99v-3.53l31.4.03zM52.96 11.73h13.9c3.66 0 6.64 2.24 6.64 5v3.54H46.32v-3.54c0-2.76 2.98-5 6.64-5z"
      />
      <path
        className="text-blue fill-current"
        d="M147.44 7.5h-13.9c-6 0-10.87 4.13-10.87 9.22v30.8c0 1.17.96 2.13 2.12 2.13 1.16 0 2.11-.96 2.11-2.12v-10.3h20.54c6 0 10.87-4.13 10.87-9.22v-11.3c0-5.08-4.88-9.22-10.87-9.22zm-20.54 9.22c0-2.76 2.98-5 6.64-5h13.9c3.66 0 6.64 2.24 6.64 5v11.3c0 2.75-2.98 4.98-6.64 4.98H126.9V16.72z"
      />
      <path
        className="text-blue fill-current"
        d="M188.7 20.17l-.15-.02h-19.04a4.24 4.24 0 01-4.22-4.22c0-2.3 1.87-4.19 4.16-4.22h25.14c1.16 0 2.11-.96 2.11-2.12a2.13 2.13 0 00-2.18-2.11H169.48a8.5 8.5 0 00-8.45 8.45 8.5 8.5 0 008.42 8.45H188.39c2.3.09 4.14 2 4.14 4.3 0 2.35-1.94 4.3-4.3 4.3h-.05l-25-.01h-.03c-1.16 0-2.11.95-2.11 2.11s.95 2.12 2.11 2.12H187.84l.08-.01h.11l.22.01h.01a8.56 8.56 0 008.52-8.52 8.56 8.56 0 00-8.08-8.51z"
      />
    </svg>
  );
}

export const AddIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      height="20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#fff">
        <path d="M10.5 0a10 10 0 1 0 10 10 10.011 10.011 0 0 0-10-10Zm0 17.5A7.5 7.5 0 1 1 18 10a7.509 7.509 0 0 1-7.5 7.5Z" />
        <path d="M14.875 8.807h-2.973a.208.208 0 0 1-.209-.209V5.625a.798.798 0 0 0-.796-.796h-.795a.798.798 0 0 0-.795.796v2.973a.208.208 0 0 1-.209.209H6.125a.797.797 0 0 0-.796.795v.795a.798.798 0 0 0 .796.796h2.973a.208.208 0 0 1 .209.209v2.973a.797.797 0 0 0 .795.795h.795a.797.797 0 0 0 .796-.795v-2.973a.208.208 0 0 1 .209-.209h2.973a.798.798 0 0 0 .796-.796v-.795a.798.798 0 0 0-.796-.795Z" />
      </g>
    </svg>
  );
};

const navigation = [
  { href: "/", icon: HomeIcon, isActive: new RegExp("^/$"), name: "Dashboard" },
  {
    href: "/notification",
    icon: NewspaperIcon,
    isActive: new RegExp("^/notification"),
    name: "Meldungen",
  },
];

type ItemLink = PropsWithChildren<{
  href: string;
}>;

function ItemLink(props: ItemLink) {
  const { asPath } = useRouter();
  const { children, href, ...rest } = props;
  return (
    <li>
      <Link
        aria-current={asPath === href ? "page" : undefined}
        className={`group flex gap-x-3 px-6 py-3 transition hover:bg-blue-50 focus-visible:bg-blue-50 focus-visible:ring-3 focus-visible:ring-blue-400 focus-visible:outline-hidden focus-visible:ring-inset ${
          asPath === href ? "bg-blue-50" : ""
        }`}
        href={href}
        {...rest}
      >
        {children}
      </Link>
    </li>
  );
}

interface ItemProps {
  href: string;
  icon: string;
  subtitle?: string;
  title: string;
}

function Item({ href, icon, subtitle, title }: ItemProps) {
  return (
    <MenuItem>
      <ItemLink href={href}>
        <svg className="inline-block h-6 w-6 shrink-0">
          <use xlinkHref={`/img/icon-sprite.svg#${icon}`} />
        </svg>
        <span className="flex flex-col gap-y-0.5">
          <span className="font-bold text-blue-800">{title}</span>
          {subtitle && (
            <span className="text-sm text-gray-600">{subtitle}</span>
          )}
        </span>
      </ItemLink>
    </MenuItem>
  );
}

interface HeaderMenuProps {
  children: ReactElement<ItemProps>[];
  title: string;
  variant?: "blue" | "default";
}

const menuButtonClassNames = {
  blue: "flex items-center px-4 py-2 text-sm font-bold uppercase transition rounded-full md:px-6 text-white bg-blue-700 hover:bg-blue-800 focus-visible:ring-3 focus-visible:ring-blue-400 focus-visible:outline-hidden",
  default:
    "flex items-center rounded-full px-4 py-2 text-sm font-bold uppercase transition text-blue hover:text-green focus-visible:outline-hidden focus-visible:ring-3 focus-visible:ring-blue-400 md:px-6",
};

const openedMenuButtonClassNames = {
  blue: "bg-blue-800",
  default: "text-green",
};

function HeaderMenu({ children, title, variant = "default" }: HeaderMenuProps) {
  return (
    <Menu as="li">
      <MenuButton className={menuButtonClassNames[variant]}>
        {({ open }) => {
          return (
            <>
              <span
                className={`whitespace-nowrap ${
                  open ? openedMenuButtonClassNames[variant] : ""
                }`}
              >
                {title}
              </span>
              <svg
                className={`inline-block h-5 w-5 shrink-0 transition-transform ${
                  open
                    ? `rotate-180 ${openedMenuButtonClassNames[variant]}`
                    : ""
                }`}
              >
                <use xlinkHref="/img/icon-sprite.svg#chevron-down" />
              </svg>
            </>
          );
        }}
      </MenuButton>
      <MenuItems
        as="ul"
        className="absolute top-full right-0 left-0 mt-1 flex flex-col gap-y-px overflow-hidden rounded-xs bg-white shadow-md shadow-gray-500/20 md:right-0 md:left-auto md:w-[300px]"
      >
        {children}
      </MenuItems>
    </Menu>
  );
}

HeaderMenu.Item = Item;

const containerClassName = "px-4 2xl:container";

export default function Layout({
  children,
  title,
}: PropsWithChildren<{
  title: string;
}>) {
  const router = useRouter();
  const { setConfig } = useConfigContext();
  const authUser = useAuthUser();
  const config = useConfig();

  if (!authUser) {
    // TODO: show loading screen while fetching user data?!
    return null;
  }

  return (
    <div className="">
      <Head>
        <title>{title}</title>
      </Head>
      <div className="bg-white shadow-lg shadow-slate-200">
        <div
          className={twMerge(
            "flex h-24 items-center justify-between",
            containerClassName,
          )}
        >
          <Link className="text-blue text-4xl font-bold" href="/">
            <Logo className="-ml-3 h-3" />
            MOCO
          </Link>
          <nav className="h-full">
            <ul className="flex h-full">
              {navigation.map((item) => {
                return (
                  <li
                    className={twMerge(
                      `-mb-1 border-b-4 border-transparent`,
                      // item.isActive.test(router.pathname)
                      //   ? "border-slate-300"
                      //   : "",
                    )}
                    key={item.href}
                  >
                    <Link
                      className={`text-blue relative flex h-full items-center space-x-3 border-2 border-transparent px-4 text-sm font-medium outline-hidden hover:bg-slate-50 focus-visible:border-blue-500 ${
                        item.isActive.test(router.pathname) ? "bg-slate-50" : ""
                      }`}
                      href={item.href}
                    >
                      <item.icon aria-hidden="true" className="h-6" />
                      <span>{item.name}</span>
                    </Link>
                  </li>
                );
              })}
              <Menu as="li" className="relative">
                {({ close, open }) => {
                  return (
                    <>
                      <MenuButton
                        className={`text-blue flex h-full cursor-pointer items-center space-x-3 border-2 border-transparent p-4 text-sm font-medium outline-hidden hover:bg-slate-50 focus-visible:border-blue-500 ${
                          open ? "bg-slate-50" : ""
                        }`}
                      >
                        <UserCircleIcon className="h-6" />
                        <span>Account</span>
                        <ChevronDownIcon
                          className={`h-5 transition-transform ${
                            open ? "rotate-180" : ""
                          }`}
                        />
                      </MenuButton>
                      <MenuItems className="absolute right-0 z-20 mt-2 max-h-[50vh] w-64 overflow-auto rounded-lg rounded-b border border-slate-200 bg-white shadow-lg">
                        <div className="border-b border-slate-200 bg-slate-100 px-4 py-3 text-sm font-medium text-gray-500">
                          <div className="flex flex-col">
                            <div className="font-semibold text-blue-950">
                              {authUser?.displayName}
                            </div>
                            <div className="font-medium text-cyan-800">
                              {config?.title}
                            </div>
                          </div>
                        </div>
                        {authUser?.configs
                          ?.sort((a, b) => {
                            return a.title.localeCompare(b.title);
                          })
                          .map((item: Config) => {
                            const { settings, slug, title: itemTitle } = item;
                            const isSelected = slug === config?.slug;
                            return (
                              <button
                                className={`flex w-full items-center space-x-4 py-2.5 pl-4 text-sm text-cyan-800 ${
                                  isSelected
                                    ? "pointer-events-none bg-lime-50 font-bold"
                                    : "cursor-pointer font-medium hover:bg-slate-50"
                                }`}
                                key={slug}
                                onClick={() => {
                                  localStorage.setItem("moco.config", slug);
                                  if (
                                    settings?.extent_topographic !==
                                    config?.settings?.extent_topographic
                                  ) {
                                    window.localStorage.setItem(
                                      LS_MAP_EXTENT_KEY,
                                      JSON.stringify(
                                        getExtentFromString(
                                          settings?.extent_topographic,
                                        ),
                                      ),
                                    );
                                  }

                                  setConfig(item);
                                  close();
                                  router.push("/").catch((err) => {
                                    console.error(
                                      "Layout component: can't navigate to / after new config selection",
                                      err,
                                    );
                                  });
                                }}
                              >
                                <div
                                  className={`h-2 w-2 rounded-full ${
                                    isSelected ? "bg-lime-500" : ""
                                  }`}
                                />
                                <div>{itemTitle}</div>
                              </button>
                            );
                          })}
                        <MenuItem>
                          <a
                            className={`text-blue flex h-full items-center space-x-2 border-slate-200 py-2.5 pl-4 text-sm font-medium hover:bg-slate-50 ${authUser?.configs?.length ? "border-t" : ""}`}
                            href="/backend/oidc/logout/"
                            onClick={() => {
                              localStorage.removeItem(PERSIST_KEY);
                            }}
                          >
                            <ArrowRightOnRectangleIcon className="-ml-1 w-5" />
                            <span>Abmelden</span>
                          </a>
                        </MenuItem>
                      </MenuItems>
                    </>
                  );
                }}
              </Menu>
              <div className="relative flex h-full items-center pl-4">
                <Button
                  onClick={() => {
                    void router.push("/notification/add");
                  }}
                  variant="green"
                >
                  <AddIcon />
                  Meldung
                </Button>
              </div>
            </ul>
          </nav>
        </div>
      </div>
      {config ? (
        <main className={twMerge(containerClassName, "my-8")}>{children}</main>
      ) : (
        <div className={twMerge(containerClassName, "my-8 text-red-500")}>
          {/* Keine Konfiguration gefunden. */}
        </div>
      )}
    </div>
  );
}
